import React, { FC, useState, ChangeEvent } from 'react'
import { Box, FormControlLabel, Switch } from '@mui/material'
import { OrbitControls } from '@react-three/drei'
import { ARCanvas } from '@react-three/xr'
import ArObject from './ar-object'

const App:FC = () => {
    const [hitTestingEnabled, setHitTestingEnabled] = useState(false)

    const hitTestChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setHitTestingEnabled(event.target.checked)
    }

    return <>
        <Box component="div" sx={{
            width: '100vw',
            height: '90vh',
            position: 'absolute',
            left: 0,
            top: 0,
            textAlign: 'center',
        }}>
            <Box component="div" sx={{
                position: 'absolute',
                right: '20px',
                top: '140px',
                zIndex: 200,
            }}>
                <FormControlLabel control={<Switch value={true} checked={hitTestingEnabled} onChange={hitTestChangeHandler} />} label="Hit testing" />
            </Box>
            <Box component="div" sx={{
                position: 'absolute',
                width: '100vw',
                height: '90vh'
            }}>
                <ARCanvas
                    referenceSpace="local"
                    sessionInit={{
                        requiredFeatures: ['hit-test'],
                        optionalFeatures: ['dom-overlay', 'dom-overlay-for-handheld-ar'],
                        domOverlay: { root: document.body }
                    }}
                >
                    <pointLight position={[10, 10, 10]} />
                    <ambientLight intensity={0.5} />
                    <OrbitControls />
                    <ArObject hitTestingEnabled={hitTestingEnabled} />
                </ARCanvas>
            </Box>
        </Box>
    </>
}

export default App
