import React, { FC, useRef, useState, useMemo } from 'react'
import { useHitTest } from '@react-three/xr'
import { BoxBufferGeometry, Matrix4, Mesh, MeshNormalMaterial, Group } from 'three'
import { Box } from '@react-three/drei'
import { useGLTF } from '@react-three/drei'

interface Props {
    hitTestingEnabled: boolean
}

const ArObject:FC<Props> = (props) => {
    // const meshRef = useRef() as any
    const { scene } = useGLTF(`${process.env.REACT_APP_PUBLIC_URL}/door.glb`)

    const [hitTestObjects, setHitTestObjects] = useState<Group[]>([])

    // const [initialMatrix4, setInitialMatrix4] = useState<Matrix4|undefined>(undefined)
    //
    // const mesh = useMemo(() => {
    //     return new Mesh(new BoxBufferGeometry(10, 10, 100), new MeshNormalMaterial())
    // }, [])
    //

    useHitTest((hitMatrix: Matrix4, hit: XRHitTestResult) => {
        if (props.hitTestingEnabled) {
            const hitGroup = new Group()

            const hitTestObject = new Mesh(new BoxBufferGeometry(0.1, 0.1, 0.1), new MeshNormalMaterial())

            hitGroup.applyMatrix4(hitMatrix)

            if (props.hitTestingEnabled) {
                hitGroup.add(hitTestObject)
            } else {

            }
            hitGroup.add(scene)


            setHitTestObjects([hitGroup])
        }

        // if (initialMatrix4 === undefined) {
        //     setInitialMatrix4(mesh.matrix.clone())
        // } else {
        //     mesh.applyMatrix4(initialMatrix4)
        //     mesh.updateMatrix()
        //     mesh.updateMatrixWorld()
        // }
        //
        // mesh.applyMatrix4(hitMatrix)
        // mesh.updateMatrix()

    })

    return <>
        {/*<primitive object={mesh} />*/}
        {hitTestObjects.map(object => {
            return <primitive object={object} />
        })}
        {/*<mesh position={new Vector3(3, 0, 0)}>*/}
        {/*    <boxGeometry args={[1, 1, 1]} />*/}
        {/*    <meshNormalMaterial />*/}
        {/*</mesh>*/}

        {/*<mesh ref={meshRef}>*/}
        {/*    <boxGeometry args={[10, 10, 100]} />*/}
        {/*    <meshNormalMaterial />*/}
        {/*</mesh>*/}
    </>
}

const ArObject2:FC = () => {
    const ref = useRef()

    useHitTest((hit) => {
        // @ts-ignore
        hit.decompose(ref.current.position, ref.current.rotation, ref.current.scale)
    })

    return <Box ref={ref} args={[0.1, 0.1, 0.1]} />
}

export default ArObject
